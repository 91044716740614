import Button, { ButtonVariant } from '@/components/button'
import Link from '@/components/link'

const Hero = () => {
  return (
    <div>
      <main className='mx-auto margin-top-custom px-4'>
        <div className='text-center'>
          <p className='h-title mx-auto text-black'>
            Everyday
            <br />
            emotions
            <br />
            made simple
          </p>
          <p className='tag-txt mx-auto text-sm font-semibold text-[#234370]'>
            #We Learn Together
          </p>
          <div className='my-custom w-fit !mt-10'>
            <Link href='/program' noUnderline>
              <Button
                variant={ButtonVariant.Primary}
                tailFill='#ffffff'
                bg='bg-[#ffffff]'
                className='explore !text-[#234370] tracking-[0px] !font-bold rounded-3xl !px-3 !py-1.5'
              >
                EXPLORE ALL COURSES
              </Button>
            </Link>
          </div>
        </div>
      </main>
      <style jsx>{`
        .h-title {
          font-size: 56.31pt;
          line-height: 67.37pt;
          font-weight: 200;
          margin-bottom: 32px;
        }
        .tag-txt {
          font-size: 24px;
          letter-spacing: -0.55px;
          line-height: 1;
        }
        .my-custom {
          margin: 50px auto;
        }
        @media (max-width: 1920px) {
          .h-title {
            font-size: calc(
              23pt + (56.31 - 23) * (100vw - 281pt) / (1920 - 375)
            );
            margin-bottom: calc(
              11pt + (32 - 11) * (100vw - 281pt) / (1920 - 375)
            );
            line-height: calc(
              26pt + (67.37 - 11) * (100vw - 281pt) / (1920 - 375)
            );
          }
          .tag-txt {
            font-size: calc(11px + (24 - 11) * (100vw - 375px)/(1920 - 375));
          }
          .my-custom {
            margin: calc(20px + 30 * (100vw - 375px)/(1920 - 375)) auto;
          }
        }
      `}</style>
    </div>
  )
}

export default Hero
