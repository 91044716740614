import bottomImg from '@/assets/illustrations/home.svg'
import topImg from '@/assets/illustrations/homecafelogo.svg'

import Hero from './components/hero'

const Home = () => {
  return (
    <div className='flex h-[100%] flex-wrap' style={{ background: '#B1F8F9' }}>
      <div className='flex h-fit w-full flex-wrap'>
        <div className='flex-[0_0_100%] self-start'>
          <img src={`${topImg.src}`} className='t-img m-auto' />
        </div>
        <div className='flex-[0_0_100%] self-center'>
          <Hero />
        </div>
      </div>

      <div className='flex-[0_0_100%] self-end'>
        <img src={`${bottomImg.src}`} className='m-auto' />
      </div>
      <style jsx>{`
        .t-img {
          width: 288px;
        }
        @media (max-width: 1920px) {
          .t-img {
            width: calc(140px + (288 - 140) * (100vw - 375px) / (1920 - 375));
          }
        }
      `}</style>
    </div>
  )
}

export default Home
